<template>
    <div>
        <h1 class="title">
            Test {{ test }}
        </h1>
    </div>
</template>

<script>

export default {
    data() {
        return {
            test: 'copter',
        }
    }
}

</script>
